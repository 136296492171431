import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import './Nav.scss'

const Nav = () => {

    const location = useLocation()

    return (
        <nav>
            <div className={`souligne ${location.pathname !== "/demain" ? "left" : "right"}`} />
            <div>
                <Link to="/">Aujourd'hui</Link>
            </div>
            <div>
                <Link to="/demain">Demain</Link>
            </div>
        </nav>
    )
}

export default Nav