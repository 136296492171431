const reducer = (state = { isLog: false, calendrier: [] }, action) => {

    const newState = { ...state }

    if (action.type === "CONNEXION_ASYNC") {
        newState.isLog = action.isLog
        newState.me = action.me
    }

    if (action.type === "GET_LECONS_ASYNC") {
        newState.lecons = action.lecons
    }

    return newState
}

export default reducer