import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Nav from './Nav'

import { Hours } from './utils'

import './Lecons.scss'
import LOAD from './../images/load.png'

const reloadSecondes = 10

const months = ['jan', 'fev', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'dec']

export function TimestampToDayMonthYearHoursMinutes(timestamp) {
    const date = new Date(timestamp * 1000)
    const mois = months[date.getMonth()]
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const heures = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${jour} ${mois} ${date.getUTCFullYear()} à ${heures}h${minutes}`
}

const Loader = () => {
    return (
        <div className="loader">
            <p>Chargement
                <img
                    className="loading"
                    src={LOAD} />
            </p>
        </div>
    )
}

const Lecons = ({ lecons, getLecons, absent }) => {


    const route = useLocation()

    const [jour, setJour] = useState(route.pathname === "/demain" ? 1 : 0)

    useEffect(()=>{setJour(route.pathname === "/demain" ? 1 : 0)},[route])


    useEffect(() => {
        getLecons()
        const timer = setInterval(() => {
            getLecons()
        }, reloadSecondes * 1000)
        return () => clearInterval(timer)
    }, [])

    return (
        <div className='lecons'>
            <Nav />
            {
                lecons[jour].length === 0 ? <div><Loader /><button onClick={() => getLecons()}>ClickMe</button></div> :
                    <div className='liste'>
                        <h1>Les leçons du jour:</h1>
                        <button onClick={getLecons} style={{ marginBottom: "35px" }}>Rafraîchir</button>
                        {lecons[jour].map(lecon =>
                            <div key={lecon.id} className="ligne">
                                <p className={lecon.status !== 1 ? 'absent' : ''}>
                                    <strong>{Hours(lecon.debut)}</strong> à
                                    <strong>{Hours(lecon.fin)}</strong> 
                                -> {lecon.prenom} {lecon.nom}
                                </p>
                                <div className='heures'>
                                    <div>
                                        <strong className={lecon.status !== 1 ? 'absent' : ''}>{lecon.heures_passees}h</strong> déjà effectuées | <strong className={lecon.status !== 1 ? 'absent' : ''}>{lecon.credit_heures - lecon.heures_passees}h</strong> restant
                                    </div>
                                    <button className={`saumon ${lecon.status === 1 ? 'creux' : ''}`} onClick={() => absent(lecon.id)}>{lecon.status === 1 ? "Absent" : "Présent"}</button>
                                </div>
                                {lecon.examen && <div className='exam'>Prochain examen le <strong>{TimestampToDayMonthYearHoursMinutes(lecon.examen)}</strong></div>}
                            </div>
                        )}
                    </div>
            }
        </div>
    )
}

const mapToProps = state => {
    return {
        lecons: state.lecons || [[], []]
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLecons: () => dispatch({ type: `GET_LECONS` }),
        absent: (id) => dispatch({ type: 'SET_ABSENT', id })
    }
}


export default connect(mapToProps, mapDispatchToProps)(Lecons)