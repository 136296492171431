import '@babel/polyfill';
import { put, takeEvery, call, take } from 'redux-saga/effects'
import { apiCall, logOut } from './apiCall'

function* connexion(email, password) {
    let { me } = yield call(apiCall, "POST", "/login", { email, password })
    // console.log("me: ", me)
    console.log("me: ", me)
    yield put({ type: 'CONNEXION_ASYNC', isLog: true, me })
}

function* deconnexion() {
    let { logout } = yield call(logOut)
    yield put({ type: 'CONNEXION_ASYNC', isLog: !logout })
}

function* getLecons() {
    let { lecons } = yield call(apiCall, "POST", "/mesLecons", {})
    console.log("Depuis saga, les leçons: ")
    yield put({ type: 'GET_LECONS_ASYNC', lecons })
}

function* setAbsent(id) {
    console.log("Depuis saga, on change de status de la leçon ", id)
    let { lecons } = yield call(apiCall, "POST", "/setStatus", { id })
    yield put({ type: 'GET_LECONS_ASYNC', lecons })
}



export default function* asyncCalls() {
    yield takeEvery('CONNEXION', ({ email, password }) => connexion(email, password))
    yield takeEvery('DECONNEXION', () => deconnexion())
    yield takeEvery('GET_LECONS', () => getLecons())
    yield takeEvery('SET_ABSENT', ({ id }) => setAbsent(id))
}