import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

import MILKY from './../images/MilkyW.png'

import './Connexion.scss'

import Switch from './Switch'

const Connexion = ({ connexion }) => {

    const [showMdp, setShowMdp] = useState(true)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    function _connect() {
        connexion(email, password)
    }

    return (
        <div className='connexion'>
        <section>
            <Outlet />
            <img src={MILKY} />
            <h2>Espace moniteur</h2>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder='e-mail' />
            <input type={showMdp ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)} placeholder='mot de passe'/>
            <Switch label="Afficher mon mot de passe" initiale={showMdp} devientOn={()=>setShowMdp(true)} devientOff={()=>setShowMdp(false)}/>
            <button onClick={() => _connect()}>Je me connecte</button>
        </section>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        connexion: (email, password) => dispatch({ type: `CONNEXION`, email, password }),
    }
}


export default connect(null, mapDispatchToProps)(Connexion)