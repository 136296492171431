
import { connect } from 'react-redux'
import Connexion from './pages/Connexion'
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"

import Lecons from './pages/Lecons'


import './App.scss'

const Structure = ({ deconnexion }) => {
  return (
    <div>
      <section>
        <Outlet />
        <button className='deconnexion' onClick={deconnexion}>Deconnexion</button>
      </section>
    </div>
  )
}

const App = ({ isLog, deconnexion }) => {
  if (!isLog) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Connexion />} >
            <Route path="/connection-fail" element={<div className='bad-pass'>Mauvais email ou mot de passe</div>} />
            <Route path="*" element={<p>Page non trouvée</p>} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Structure deconnexion={deconnexion} />} >
            <Route path="/" element={<Lecons />} />
            <Route path="/demain" element={<Lecons />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

const mapToProps = state => {
  return {
    isLog: state.isLog
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deconnexion: () => dispatch({ type: `DECONNEXION` })
  }
}


export default connect(mapToProps, mapDispatchToProps)(App)
